<script setup lang="ts">
const menuItems = useNavigationMenu().navigationFooter()
function mailtoContact() {
  const mail = 'mailto:drivmedla@vti.se'
  const a = document.createElement('a')
  a.href = mail
  a.click()
}
</script>

<template>
  <div class="footer w-full flex justify-content-between align-items-center">
    <div>
      <img class="eu-logo" src="~/assets/EU-logo.png" alt="EU logotyp" />
    </div>
    <div
      class="footer__items flex text-right align-items-end md:align-items-center md:text-center gap-6"
    >
      <span v-for="item of menuItems" :key="item.label">
        <NuxtLink :to="item.to">
          {{ item.label }}
        </NuxtLink>
      </span>
      <Button
        class="green-button outlined"
        label="Kontakt"
        severity="secondary"
        size="small"
        outlined
        rounded
        @click="mailtoContact()"
      />
    </div>
  </div>
</template>

<style scoped lang="scss">
@import 'App.scss';

.eu-logo {
  height: 50px;
}

.footer {
  background-color: $white;
  border-top: 1px solid $lightGray;
  padding: 1rem;
  min-height: 3rem;

  &__items {
    flex-direction: column;

    @include tablet-landscape-up() {
      flex-direction: row;
    }
  }

  a {
    color: $navigation;
  }
}
</style>
