<script setup lang="ts">
import AppTopBar from '../components/app/AppTopbar.vue'
import AppFooter from '../components/app/AppFooter.vue'
import { useThemeStore } from '@/stores'

const themeStore = useThemeStore()
themeStore.setLight()
</script>

<template>
  <div>
    <Link
      rel="stylesheet"
      :href="
        themeStore.link ||
        'https://cdn.jsdelivr.net/npm/primevue@3.15.0/resources/themes/vela-blue/theme.css'
      "
    />
    <div>
      <AppTopBar />
      <div class="layout-main-container">
        <div class="layout-main">
          <slot />
        </div>
      </div>
      <AppFooter />
    </div>
  </div>
</template>
